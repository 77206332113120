export const IMAGE_TAB_INDEX = -1;

const ARTICLE_IMAGE_HEIGHT = 447;
const ARTICLE_IMAGE_WIDTH = 672;

export const MIN_STRING_LENGTH = 0;
export const MAX_STRING_LENGTH = 118;

export const BLOG_LINK = 'blog';

export const ARTICLE_IMAGE_SIZES = {
  height: ARTICLE_IMAGE_HEIGHT,
  width: ARTICLE_IMAGE_WIDTH,
};
